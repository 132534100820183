<!--
 * @Author: wxy
 * @Describe: 
 * @LastEditors: wxy
 * @LastEditTime: 2021-06-21 19:54:51
-->
<template>
  <a-row :gutter="20" class="margin-bottom" type="flex" justify="end" align="middle">
    <!-- <a-col>
      <a-select
          v-model:value="filterState.active_type"
          style="width: 200px"
          @change="search"
          placeholder="選擇活動類型"
          allow-clear
      >
        <a-select-option :value="1">物資</a-select-option>
        <a-select-option :value="2">團購</a-select-option>
        <a-select-option :value="3">義工</a-select-option>
      </a-select>
    </a-col>

    <a-col>
      <a-input
          v-model:value="filterState.keyword"
          placeholder="輸入關鍵字搜索"
          allow-clear
      />
    </a-col> -->

    <a-col>
      <a-button type="primary">搜索</a-button>
    </a-col>
  </a-row>

  <a-table
    :dataSource="dataSource"
    :columns="columns"
    :loading="tableLoading"
    :pagination="pagination"
    :scroll="{
      x: 0,
      y: 600,
      scrollToFirstRowOnChange: true,
    }"
    @change="tableChange"
  />
</template>

<script>
import useTable from "@/composition/useTable";
import { ref } from "vue";
import useTableFilter from "@/composition/useTableFilter";

export default {
  setup() {
    const dataSource = ref([]);
    for (let i = 0; i < 100; i++) {
      dataSource.value.push({
        key: i,
        name: "胡彦斌",
        age: 32,
        address: "西湖区湖底公园1号",
      });
    }
    const {
      pagination,
      scroll,
      tableChange,
      editData,
      tableLoading,
      getData,
      settingData,
    } = useTable({ get: "/" });

    // 篩選數據
    const { filterState, filterFn } = useTableFilter("/organize/history", {
      keyword: "",
      active_type: "",
    });

    const search = async () => {
      const res = await filterFn();
      settingData(res);
    };

    return {
      filterState,
      search,
      filterFn,
      pagination,
      scroll,
      tableChange,
      editData,
      tableLoading,
      getData,
      dataSource,
      columns: [
        {
          title: "姓名",
          dataIndex: "name",
          key: "name",
        },
        {
          title: "年龄",
          dataIndex: "age",
          key: "age",
        },
        {
          title: "住址",
          dataIndex: "address",
          key: "address",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped></style>
